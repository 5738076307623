import './App.css';
import {
  TwitterOutlined,
  InstagramFilled,
} from '@ant-design/icons';
import "antd/dist/antd.css";
import { Button, Col, Row, Card, BackTop, Collapse } from "antd";
import { Steps } from 'antd';
import { Image } from 'antd';
import { Tabs } from 'antd';
import Navbar from 'responsive-react-js-navbar';

const { TabPane } = Tabs;
const { Meta } = Card;

function callback(key) {
  console.log(key);
}
const { Step } = Steps;
const { Panel } = Collapse;

function App() {
  const links = [{
    "href": "#rarity",
    "label": "RARITY",
    "background": false,
},
{
    "href": "#staking",
    "label": "STAKING",
    "background": false,
},
{
    "href": "#team",
    "label": "TEAM",
    "background": false,
},
{
    "href": "#roadmap",
    "label": "ROAD MAP",
    "background": false,
},
{
    "href": "#faq",
    "label": "FAQ",
    "background": false,
}
]


  return (
    <>
    <div className="App">
     <div className="Container">
     
            <Navbar
                logo='https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641846165/starchildz/main-logo_kasrny.png'
                logoHref='/'
                links={links}
            />
       
      <div style={{ margin: 32, textAlign: "Left", }}>
      <Row align="middle" gutter={[4, 4]}>
      <Col xs={24} sm={24} md={12} lg={12}>
      <h1>5,000 Randomly Generated STAR-CHILDZ living on the Blockchain! <br/>Mint price: 0.1 ETH</h1>
      <p>1,000 Star Childz NFT’s will be available starting <br/>13th of March 2022 (whitelist), at 0.05 ETH.</p>
      <br></br>
      <Button
              
              size="large"
              shape="round"
              href="/StarChildz-Whitepaper.pdf"
              target="_blank"
            >
              <span style={{ marginRight: 8 }} role="img" aria-label="support">
                💬
              </span>
              WHITEPAPER
            </Button>
            <br/><br/>
      </Col>
     
          <Col xs={24} sm={24} md={12} lg={12}>
          <img
      width={'100%'}
      src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1647029398/starchildz/StarChildz_rhmtw7.gif"
    />
         
          </Col>

          
        </Row>
         
        
      </div>
      <div style={{ margin: 32 }}>

      <h3 style={{ marginBottom:"0px" }}>Join the White-List</h3>
      <div class="ml-form-embed"
      data-account="509885:c9k4y7w8a6"
      data-form="5448230:g4j8c2">
      </div>
       
      </div>
      <div style={{ margin: 32, textAlign: "Left", }}>
      <Row align="middle" gutter={[4, 4]}>
      <Col xs={24} sm={24} md={12} lg={12}>
      <h3>OUR STORY</h3>
        <p style={{ paddingRight:"15px" }}>The Star Childz NFT Collection was created to give buyers exclusive access to a true Community of wellness and upliftment.
        We aim to create social events and educational mini RPG Games in the Metaverse. Adventure to a fantasy world filled with monster’s
        magic and enemies with your Star Childz. <br/>As you search to find your true self and make it to the Peaceful land. 
        Each NFT Holder with be issued tokens. These Tokens with used to purchase exclusive wearable NFT’s for their avatar (currently sandbox).
        Plus, access to our private discord, Merchandise and Events – off and online. </p>
          <br></br>
      <Button
              size="large"
              shape="round"
              href="/StarChildz-Whitepaper.pdf"
              target="_blank"
            >
              <span style={{ marginRight: 8 }} role="img" aria-label="support">
                💬
              </span>
              WHITEPAPER
            </Button>
            <br/><br/>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
          <img
      width={'100%'}
      src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641846166/starchildz/39_wimnmt.jpg"
    />
         
          </Col>
            </Row>

      </div>
      <div style={{ margin: 32, textAlign: "Left", }}>
      <h3 id="rarity">RARITY</h3>
      <Tabs defaultActiveKey="1" onChange={callback} centered>
    <TabPane tab="Background" key="1">
    <div className="site-card-wrapper">
    <Row gutter={16}>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="red" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847498/starchildz/0-background/33-red_i0eq7p.png" />}
      >
    <Meta title="Red Zone" description="rarity: 33%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="orange" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847498/starchildz/0-background/22-orange_fq15kr.png" />}
      >
    <Meta title="Orange Zone" description="rarity: 22%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="yellow" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847498/starchildz/0-background/13-yellow_dkx85c.png" />}
      >
    <Meta title="Yellow Zone" description="rarity: 13%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="green" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847498/starchildz/0-background/12-green_jvmyrc.png" />}
      >
    <Meta title="Green Zone" description="rarity: 12%" />
  </Card>
      </Col>
    </Row><br/>
    <Row gutter={16}>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Blue" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847499/starchildz/0-background/9-blue_px2ibd.png" />}
      >
    <Meta title="Blue Zone" description="rarity: 9%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Light blue" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847499/starchildz/0-background/8-light-blue_vhryco.png" />}
      >
    <Meta title="Light Blue Zone" description="rarity: 8%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Purple" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847498/starchildz/0-background/2-purple_wil6bk.png" />}
      >
    <Meta title="Purple Zone" description="rarity: 2%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Light Purple Zone" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847498/starchildz/0-background/1-light-purple_abugm7.png" />}
      >
    <Meta title="Light Purple Zone" description="rarity: 1%" />
  </Card>
      </Col>
    </Row>
  </div>
     
    </TabPane>
    <TabPane tab="Elements" key="2">
    <Row gutter={16}>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="None" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847499/starchildz/1-features/65-none_kvpfta.png" />}
      >
    <Meta title="None" description="rarity: 65%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Ice" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847499/starchildz/1-features/10-ice-energy_br8kse.png" />}
      >
    <Meta title="Ice Energy" description="rarity: 10%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Fire" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847499/starchildz/1-features/10-fire-energy_xu2auh.png" />}
      >
    <Meta title="Fire Energy" description="rarity: 10%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Stars" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/1-features/5-stars_ttwddc.png" />}
      >
    <Meta title="Stars" description="rarity: 5%" />
  </Card>
      </Col>
    </Row><br/>
    <Row gutter={16}>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Blue flames" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/1-features/5-blue-flames_xgbcuk.png" />}
      >
    <Meta title="Blue flames" description="rarity: 5%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="flames" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/1-features/3-flames_bc6bix.png" />}
      >
    <Meta title="Flames" description="rarity: 3%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="power up" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847499/starchildz/1-features/1-power-up_r8jpii.png" />}
      >
    <Meta title="Power up" description="rarity: 1%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Gold power" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847499/starchildz/1-features/1-gold_spvvrr.png" />}
      >
    <Meta title="Gold Power" description="rarity: 1%" />
  </Card>
      </Col>
    </Row>
    </TabPane>
    <TabPane tab="Body" key="3">
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="red body" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/2-body/22-red-body_ughehn.png" />}
      >
    <Meta title="Red Body" description="rarity: 22%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Orange body" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/2-body/21-orange-body_jjxdec.png" />}
      >
    <Meta title="Orange Body" description="rarity: 21%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Yellow body" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/2-body/20-yellow-body_n13eh3.png" />}
      >
    <Meta title="Yellow Body" description="rarity: 20%" />
  </Card>
      </Col> 
    </Row><br/>
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Green Body" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/2-body/18-green-body_h1aexz.png" />}
      >
    <Meta title="Green Body" description="rarity: 18%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Blue Body" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/2-body/10-blue-body_jaqqcv.png" />}
      >
    <Meta title="Blue Body" description="rarity: 10%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Purple Body" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/2-body/9-purple-body_n088xj.png" />}
      >
    <Meta title="Purple Body" description="rarity: 9%" />
  </Card>
      </Col>
    </Row>
    </TabPane>
    <TabPane tab="Mouth" key="4">
    <Row gutter={16}>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="straight" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/3-mouth/50-straight-mouth_isfeyb.png" />}
      >
    <Meta title="Straight Mouth" description="rarity: 50%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Smile Grin" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/3-mouth/14-smile-grin_mriie9.png" />}
      >
    <Meta title="Smile Grin" description="rarity: 14%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Lips" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/3-mouth/11-lips_pjbotx.png" />}
      >
    <Meta title="Lips" description="rarity: 11%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Open Mouth" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/3-mouth/9-open-mouth-gold_uas22f.png" />}
      >
    <Meta title="Open Mouth Gold" description="rarity: 9%" />
  </Card>
      </Col>
    </Row><br/>
    <Row gutter={16}>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Sad Mouth" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/3-mouth/8-sad_ghdv3p.png" />}
      >
    <Meta title="Sad Mouth" description="rarity: 8%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Grit Teeth" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/3-mouth/3-grit-teeth-gold_ew2v6l.png" />}
      >
    <Meta title="Grit Teeth Gold" description="rarity: 3%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Big Grin" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/3-mouth/3-big-grin_ezxpf5.png" />}
      >
    <Meta title="Big Grin" description="rarity: 3%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Lips open" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847500/starchildz/3-mouth/2-lips-open-gold_cvqwit.png" />}
      >
    <Meta title="Lips open Gold" description="rarity: 2%" />
  </Card>
      </Col>
    </Row>
    </TabPane>
    <TabPane tab="Eyes" key="5">
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Brown Eyes" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/4-eyes/60-brown-eyes_f12u5l.png" />}
      >
    <Meta title="Brown Eyes" description="rarity: 60%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Blue Eyes" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/4-eyes/20-blue-eyes_efmrnn.png" />}
      >
    <Meta title="Blue Eyes" description="rarity: 20%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Green Eyes" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/4-eyes/10-green-eyes_nodpoo.png" />}
      >
    <Meta title="Green Eyes" description="rarity: 10%" />
  </Card>
      </Col> 
    </Row><br/>
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Purple Eyes" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/4-eyes/5-purple-eyes_dlxndh.png" />}
      >
    <Meta title="Purple Eyes" description="rarity: 5%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Red Eyes" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/4-eyes/4-red-eyes_bnzfpu.png" />}
      >
    <Meta title="Red Eyes" description="rarity: 4%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Gold Eyes" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/4-eyes/1-gold-eyes_sxvex4.png" />}
      >
    <Meta title="Gold Eyes" description="rarity: 1%" />
  </Card>
      </Col>
    </Row>
    </TabPane>
    <TabPane tab="Clothes" key="6">
    <Row gutter={16}>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="school top" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/6-clothes/40-school_beusjn.png" />}
      >
    <Meta title="School Top" description="rarity: 40%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Orange T-Shirt" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/6-clothes/13-orange-shirt_tpgrfc.png" />}
      >
    <Meta title="Orange T-Shirt" description="rarity: 13%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Green T-Shirt" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/6-clothes/12-green-shirt_i8pw6b.png" />}
      >
    <Meta title="Green T-Shirt" description="rarity: 12%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Hoodie" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/6-clothes/10-hoodie_xmjigu.png" />}
      >
    <Meta title="Hoodie" description="rarity: 10%" />
  </Card>
      </Col>
    </Row><br/>
    <Row gutter={16}>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="V-neck" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/6-clothes/10-jumper_qpinmp.png" />}
      >
    <Meta title="v-neck Jumper" description="rarity: 10%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Cloth" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/6-clothes/5-cloth_q66ymg.png" />}
      >
    <Meta title="Cloth" description="rarity: 5%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="example" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/6-clothes/5-karate-suit_omevkm.png" />}
      >
    <Meta title="Karate Suit" description="rarity: 5%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="example" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/6-clothes/5-ninja-suit_zt4ccl.png" />}
      >
    <Meta title="Ninja Suit" description="rarity: 5%" />
  </Card>
      </Col>
    </Row>
    </TabPane>
    <TabPane tab="Symbols" key="7">
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Heart" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/5-symbols/60-hart-symbol_v4gmfc.png" />}
      >
    <Meta title="Heart Symbol" description="rarity: 60%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Affulence" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/5-symbols/20-affulence-symbol_sqnzc2.png" />}
      >
    <Meta title="Affulence Symbol" description="rarity: 20%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Courage" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/5-symbols/7-courage-symbol_bgbcyr.png" />}
      >
    <Meta title="Courage Symbol" description="rarity: 7%" />
  </Card>
      </Col> 
    </Row><br/>
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Creativity" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/5-symbols/6-creative-symbol_ljrbcy.png" />}
      >
    <Meta title="Creativity Symbol" description="rarity: 6%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Wisdom" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/5-symbols/5-wisdom-symbol_oskwd7.png" />}
      >
    <Meta title="Wisdom Symbol" description="rarity: 5%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="example" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847501/starchildz/5-symbols/2-ghana-symbol_id4ked.png" />}
      >
    <Meta title="Europe Street beat" description="rarity: 2%" />
  </Card>
      </Col>
    </Row>
    </TabPane>
    <TabPane tab="Hair" key="8">
    <Row gutter={16}>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="High-Top" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847503/starchildz/7-hair/15-high-fade_ilneld.png" />}
      >
    <Meta title="High-Top Fade" description="rarity: 15%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Long Hair" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847503/starchildz/7-hair/15-long-hair_rhb20u.png" />}
      >
    <Meta title="Long Hair" description="rarity: 15%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Fade" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847503/starchildz/7-hair/15-fade_dxn5gw.png" />}
      >
    <Meta title="Fade Hair" description="rarity: 15%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Scruffy" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847503/starchildz/7-hair/13-cruffy-hair_u50fzl.png" />}
      >
    <Meta title="Scruffy Hair" description="rarity: 13%" />
  </Card>
      </Col>
    </Row><br/>
    <Row gutter={16}>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Spiky" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847503/starchildz/7-hair/12-spiky-hair_cprffx.png" />}
      >
    <Meta title="Spiky Hair" description="rarity: 12%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Tied" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847503/starchildz/7-hair/10-tied-hair_vehnig.png" />}
      >
    <Meta title="Tied Hair" description="rarity: 10%" />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Braid" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/7-hair/10-braid-hair_o5uzre.png" />}
      >
    <Meta title="Braided Hair" description="rarity: 10%" />
  </Card>
      </Col> <Col xs={24} sm={12} md={6} lg={6}>
      <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="Afro" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641847502/starchildz/7-hair/10-afro-hair_au6qfj.png" />}
      >
    <Meta title="Afro Hair" description="rarity: 10%" />
  </Card>
      </Col>
    </Row>
    </TabPane>
  </Tabs>
    
      </div>
      <div style={{ margin: 32, textAlign: "Left", }}>
      <Row align="middle" gutter={[4, 4]}>
      <Col xs={24} sm={24} md={16} lg={16}>
      <h3 id="staking">STAKING</h3>
      <p><b>Everything Asa Token</b><br/>
          Asa sits at the core of everything in the Star Childz universe. As the universe grows more utility will be added. 
          The mission of Star Childz extends further than a 2D PFP, or a 3D avatar. 
          It`s about family, utility, and of course the main life source of any Avatar, $ASA that lets us fuel our ecosystem the TRUE way, on the blockchain.
              </p>
      <p><b>Yield</b><br/>
        Every Star Childz yields 10 $ASA a day.</p> 

      <p><b>Staking</b><br/>
        $ASA token will be distributed to STAR CHILDZ holders who stake their gods in our shared contract.
        The minimum amount of time your STARCHILDZ can be staked is 3 days.</p>

      <p><b>ASA Token</b><br/>
        $ASA can be used for a variety of things such as: future mints, raffle tickets, our new card system, merch, 
        and more to come.</p>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
          <img
      width={200}
      src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641846165/starchildz/asa-coin_jeevhu.png"
    />
         
          </Col>
              </Row>


      </div>
      <div style={{ margin: 32, textAlign: "Left", }}>
      <h3>Why Mint?</h3>
      <b><p style={{ fontSize:"18px", textAlign: "Left", }}>1.	STAR CHILDZ NFT - Produce 10 $ASA token<br/>
        2.	Each holder will receive a Free FUTURE VX avatar to mint – At Game Launch (Sandbox)<br/>
        3.	STAKING PROGRAM – Starchildz Liquidity pool to generate more staking Returns<br/>
        4.	BE PART OF AN EXCLUSIVE ECOSYSTEM – INCULDING – EXCLUSIVE DISCORD GROUP, MERCH and NFT’s </p></b>

      </div>
      <div style={{ margin: 32 }}>
      <h3 id="team">TEAM</h3>
      <div className="site-card-wrapper">
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 280 }}
      cover={<img alt="hennyx" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1641846171/starchildz/37_iaxm8c.jpg" />}
      >
    <Meta title="HennyX3000" description="CTO, Designer/Developer" />
    <br/>
    <InstagramFilled
      onClick={() => {
        window.open("https://www.instagram.com/hennyx3000/");
      }}
      style={{ fontSize: '26px'}}
      />
    <TwitterOutlined 
      onClick={() => {
        window.open("https://twitter.com/hennyx3000/");
      }}
      style={{ fontSize: '26px'}}
      />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 280 }}
      cover={<img alt="gent" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1647028675/starchildz/7_gaty4g.png" />}
      >
    <Meta title="GentOfLeisure" description="Sales, Customer Service" />
    <br/>
    <InstagramFilled
      onClick={() => {
        window.open("https://www.instagram.com/starchildznft/");
      }}
      style={{ fontSize: '26px'}}
      />
    <TwitterOutlined 
      onClick={() => {
        window.open("https://twitter.com/starchildznft");
      }}
      style={{ fontSize: '26px'}}
      />
  </Card>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8}>
      <Card
      hoverable
      style={{ width: 280 }}
      cover={<img alt="tbn" src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1647028601/starchildz/25_neb5n7.png" />}
      >
    <Meta title="To be Named" description="Community Manager" />
    <br/>
    <InstagramFilled
      onClick={() => {
        window.open("https://www.instagram.com/starchildznft/");
      }}
      style={{ fontSize: '26px'}}
      />
    <TwitterOutlined 
      onClick={() => {
        window.open("https://twitter.com/starchildznft");
      }}
      style={{ fontSize: '26px'}}
      />
  </Card>
      </Col>
    </Row>
  </div>
      </div>
      <div style={{ margin: 32 }}>
      <h3 id="roadmap">ROAD MAP</h3>
      <Steps progressDot current={0}  direction="vertical">
    
          <Step title="Level One" description="STAR CHILDZ NFT Launch - Once all sold a percentage of funds will be allocated to Liquidity pool – Creation of Asa tokens (which all NFT Holders will receive) and then Implementation of the staking Program."/>
          
          <Step title="Level Two" description="STAR CHILDZ VX Launch - The time for you to get your own STAR CHILDZ VX as a custom playable Metaverse Avatar has finally arrived. STAR CHILDZ VX are launched in partnership with The Sandbox and the integration as playable avatars into The Sandbox game is now in development." />
          <Step title="Level Three" description="The STAR CHILD Shop - Players will be able to buy custom STAR CHILDZ VX equipment and wearables to give their avatar additional playability and style during adventures in Metaverse games.
        On top of that, the launch of the STAR CHILDZ SHOP will bring added utility and perks for both the ASA Token as well as the 2D STAR CHILDZ." />
          <Step title="Level Four" description=" Amenti Island - A massive, multi-estate Paradise Island built in The Sandbox, in which we create gaming and social experiences involving all STAR CHILDZ NFTs as well as the Asa Token." />
        </Steps>
      </div>
      <div style={{ margin: 32}}>
      <h3 id="faq">FAQ</h3>
      <Collapse accordion>
    <Panel header="What is NFT?" key="1">
      <p>NFT stands for “Non-fungible token” and is a cool way of saying it’s a truly unique digital item that YOU can buy, own, and trade.</p>
    </Panel>
    <Panel header="What is Metamask?" key="2">
      <p>Metamask is a crypto-wallet that can store your Ethereum, and is needed to purchase and mint a Star-Childz. Having a wallet gives you an Ethereum address (i.e. 0xaC2x….666), this is where your NFT will be stored.</p>
    </Panel>
    <Panel header="When is the sale open and how much will it cost?" key="3">
      <p>The pre-sale will be opened on 23rd March and each Star-Childz will cost 0.05 ETH. Join our discord to learn how to get in on the pre-sale!</p>
    </Panel>
    <Panel header="Buying NFT for the first time?" key="4">
      <p>Get the Metamask Chrome or Firefox extension. Load it with ETH through services that allow you to change your money to ETH like Coinbase, Binance or Kraken. Then, click the button "MINT" on the top banner to connect your wallet and approve the transaction on Metamask. That’s it!.</p>
    </Panel>
    <Panel header="How will your market the Star-Childz NFT?" key="5">
      <p>We are working with multiple big Twitter influencers and prominent NFT drop calendars.</p>
    </Panel>
    <Panel header="Where can I see my Star-Childz after purchase?" key="6">
      <p>Your Star-Childz NFT will appear in metamask wallet. You can also see your freshly minted NFT art directly on your Opensea.io account.</p>
    </Panel>
    
  </Collapse>,
      </div>


      <div style={{ margin: 32 }}>
      <h3 >AS SEEN ON</h3>
      <Row align="middle" gutter={[4, 4]}>
      <Col xs={24} sm={24} md={12} lg={12}>
      <img
      width={'30%'}
      src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1647028508/starchildz/nft-calendar-transparent_xwez1g.png"
    />
     <br/><a href="https://nftcalendar.io/event/star-childz-nfts-and-token/" target="_blank">nftcalendar.io</a> 
      </Col>
     
          <Col xs={24} sm={24} md={12} lg={12}>
          <img
      width={'30%'}
      src="https://res.cloudinary.com/fillmore-xr-limited/image/upload/v1647028497/starchildz/NEXT-NFT-DROP_gvxwwf.png"
    />
        <br/><a href="https://www.nextnftdrop.com/projects/star-childz/" target="_blank">www.nextnftdrop.com</a> 
          </Col>

          
        </Row>
         
        
      </div>
      <div style={{ margin: 32, textAlign: "Left", }}>
      <h3>DISCLAIMER</h3>
      <p style={{ fontSize: '11px',}}>This presentation is being distributed between the proposed sponsors to assess their interest for investment in Asa Tokens "ASA”. The
        contents of this presentation have been compiled by Star-Childz team on the basis of information and documents obtained from a variety of sources,
        which constitute confidential information and is to be used solely for the purpose of evaluating an investment in Asa tokens.
        <br/>Furthermore, the Investor Presentation is intended to assist the Recipient in making their own independent evaluation of the business, and does not
        purport to contain all the information that all prospective investors may require.
        <br/>This Investor Presentation includes certain statements, estimates, analysis and projections that might change in the future depending on the situation.
        Neither Star-Childz, nor any of their affiliates or advisors have independently verified these estimates, analysis and projections, and accordingly they do not
        express any opinion or provide any form of assurance, so all the decisions you make based on provided information is at your own risk. </p>

      </div>
      <div className="icons">
      <TwitterOutlined 
      onClick={() => {
        window.open("https://twitter.com/starchildznft");
      }}
      style={{ fontSize: '26px'}}
      />
      <InstagramFilled
      onClick={() => {
        window.open("https://www.instagram.com/starchildznft/");
      }}
      style={{ fontSize: '26px'}}
      /></div>
      <div style={{ margin: 32, fontSize:"11px" }}>©FILLMORE-XR.COM. ALL COPYRIGHT SOLELY BELONGS TO FILLMORE XR LTD.</div>
      <BackTop />
    </div>
   
    </div>
    </>
  );
}

export default App;
